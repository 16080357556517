/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from 'react';

import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
  Circle
} from 'react-google-maps';

import { ExitMap } from './Map.styles';

import { Context } from '../../pages/search-and-results/search-and-results';

import CurrentDevelopmentMarker from '../../assets/icons/CurrentDevelopmentMarker.svg';
import ComingSoonMarker from '../../assets/icons/ComingSoonMarker.svg';
import SelectedMarker from '../../assets/icons/SelectedMarker.svg';

import InfoWindowContent from '../info-window-content/InfoWindowContent.js';
import MapHelpers from './MapHelpers.js';

import ContentLabel from '../content-label/ContentLabel';

function GoogleMaps({ developmentHover, developmentHoverLocation, error }) {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(true);
  const mapContext = useContext(Context);

  const {
    originalData,
    radius,
    location,
    seeAllLocations,
    initialLocation,
    searchedLocation,
    selectedPropertyTypes
  } = mapContext.state;

  const [developments, setDevelopments] = useState(originalData);

  const selectedPropertyTypeValues = selectedPropertyTypes.map(property => {
    return property.value;
  });

  const handleResize = () => {
    if (window.innerWidth >= 768) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    setDevelopments(originalData);
  }, [originalData]);

  const parseRadius = parseFloat(radius) * 1609.34; // Metres to miles conversion.

  function handleSelected(location, index) {
    setSelectedLocation(location);
    setSelectedIndex(index);
  }

  function setDefaultMarkerState(location) {
    if (location.status === 'Open') {
      return CurrentDevelopmentMarker;
    } else {
      return ComingSoonMarker;
    }
  }

  const radiusOptions = {
    fillOpacity: seeAllLocations ? 0 : 0.2,
    strokeWeight: 0
  };

  function handleMarkerIcon(location, index) {
    let defaultMarker = {
      url:
        selectedIndex === index // Cater for onClick of marker
          ? SelectedMarker
          : setDefaultMarkerState(location),
      scaledSize: new window.google.maps.Size(30, 40)
    };

    if (!developmentHover || (developmentHover && selectedIndex !== null)) {
      return defaultMarker;
    }

    // Match lat/lng inside of the developments array and return the index of the object containing them
    function searchArray(object, array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i].latitude === object || array[i].longitude === object) {
          return array.indexOf(array[i]);
        }
      }
    }

    let activeHover;

    // Search for each coordinate inside of the developments array
    developmentHoverLocation.forEach(prop => {
      activeHover = searchArray(prop, developments);
    });

    // If the index of the hovered item matches the iterated index, highlight the marker
    if (selectedIndex === null) {
      // Prevent marker highlighting whilst item is selected
      return {
        url:
          activeHover === index
            ? SelectedMarker
            : setDefaultMarkerState(location),
        scaledSize: new window.google.maps.Size(30, 40)
      };
    }
  }

  let locationLat = location ? location.latitude : null;
  let locationLng = location ? location.longitude : null;
  let locationRadius = location ? location.radius : null;

  // If search returns empty results, we still need default values to make the map appear.
  const defaultLat = 53.4807593;
  const defaultLng = -2.2426305;

  function calculateDefaultZoom(locationRadius) {
    if (error || !location) {
      return 5;
    }
    if (locationRadius && locationRadius <= 19) {
      return 9;
    }
    if (locationRadius && locationRadius <= 40) {
      return 8;
    }
    if (locationRadius && locationRadius >= 41 && locationRadius <= 80) {
      return 7;
    }

    return 6;
  }

  return (
    <>
      <GoogleMap
        zoom={seeAllLocations ? 6 : calculateDefaultZoom(locationRadius)} // Set to 5 if see all locations?
        defaultCenter={{
          lat: seeAllLocations || !location ? defaultLat : locationLat,
          lng: seeAllLocations || !location ? defaultLng : locationLng
        }}
        options={{
          streetViewControl: false,
          mapTypeControl: true,
          fullscreenControl: false,
          mapTrpeId: 'hybrid',
        }}
        style={{ flex: 1 }}
      >
        <Circle
          defaultCenter={{
            lat: locationLat,
            lng: locationLng
          }}
          radius={locationRadius * 1609.34 || parseRadius}
          options={radiusOptions}
        />
        <ExitMap
          onClick={() => {
            mapContext.dispatch({
              type: 'SET_SHOW_MAP',
              payload: false
            });
            window.dataLayer.push({
              event: 'ExitMap',
              filterPropertyType: selectedPropertyTypeValues
            });
          }}
        >
          <ContentLabel name="exitMapLabel" />
        </ExitMap>
        {developments
          ? developments.map((location, i) => {
              const latitude = parseFloat(location.latitude);
              const longitude = parseFloat(location.longitude);

              return (
                <React.Fragment key={i}>
                  {!error ? (
                    <>
                      {(location.homes && location.homes.length) || location.status == "Coming Soon" ? (
                        <Marker
                          position={{
                            lat: latitude,
                            lng: longitude
                          }}
                          onClick={() => {
                            handleSelected(location, i);
                            window.dataLayer.push({
                              event: 'MapMarkerOpen',
                              searchQuery: searchedLocation || initialLocation,
                              developmentName: location.name
                            });
                          }}
                          icon={handleMarkerIcon(location, i)}
                        />
                      ) : null}
                    </>
                  ) : null}
                </React.Fragment>
              );
            })
          : null}
        {selectedLocation && !isMobile ? (
          <InfoWindow
            position={{
              lat: parseFloat(selectedLocation.latitude),
              lng: parseFloat(selectedLocation.longitude)
            }}
            options={{
              pane: 'overlayLayer',
              pixelOffset: new window.google.maps.Size(0, -30),
              alignBottom: true,
              boxStyle: {
                width: '400px'
              },
              closeBoxURL: ''
            }}
            onCloseClick={() => {
              handleSelected(null, null);

              window.dataLayer.push({
                event: 'MapMarkerClose',
                searchQuery: searchedLocation || initialLocation,
                developmentName: selectedLocation.name
              });
            }}
          >
            <InfoWindowContent
              selectedLocation={selectedLocation}
              currentLocation={searchedLocation || initialLocation}
            />
          </InfoWindow>
        ) : null}
      </GoogleMap>
      {selectedLocation && isMobile ? (
        <InfoWindowContent
          selectedLocation={selectedLocation}
          isMobile={isMobile}
          handleSelected={handleSelected}
          currentLocation={searchedLocation || initialLocation}
        />
      ) : null}
      <MapHelpers />
    </>
  );
}

const Map = withScriptjs(withGoogleMap(GoogleMaps));

export default Map;
